@import "./../../styles/colors.scss";

/* new entry */
.ag-theme-balham .new {
    background-color: $newPastel;
}

/* edited entry */
.ag-theme-balham .edited {
    background-color: $editedPastel;
}

/* clean entry */
.ag-theme-balham .clean {
    background-color: $cleanPastel;
}

/* deleted entry */
.ag-theme-balham .deleted {
    background-color: $deletedPastel;
}

/* focused cell(s) */
.ag-theme-balham .ag-row-selected {
    background-color: rgb(183, 227, 255);
}

/* row hover */
.ag-theme-balham .ag-row-hover {
    background-color: rgb(152, 215, 255);
}

/* ag grid popup */
.ag-theme-balham .ag-popup {
    height: auto; /* resize, so you have no white background*/
}

// global seach/filter text input
.global-filter {
    margin: 8px;
}

// removes empty space in AntDesign TimePicker, which causes unnecessary scrolling
ul.ant-picker-time-panel-column::after {
    display: none;
}