@import "./../../styles/colors.scss";

/* button stripe */
//.btn-stripe {}

// new row
.newRow {
    background-color: $new;
    border-color: $new;
}
.newRow:hover,
.newRow:active,
.newRow:focus {
    background-color: $newHover;
    border-color: $newHover;
}

// delete row
.deleteRow {
    background-color: $deleted;
    border-color: $deleted;
}
.deleteRow:hover,
.deleteRow:active,
.deleteRow:focus {
    background-color: $deletedHover;
    border-color: $deletedHover;
}
