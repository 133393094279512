@import "styles/colors.scss";

.App {
    text-align: left;
    background-color: $mhx-background-color;
    min-height: 100vh;
}

.App-logo {
    color: $mhx-logo-color;
}

.App-link {
    color: #09d3ac;
}
