/* shared variables */
$mhx-background-color: rgb(241, 253, 255);
$mhx-font-color: rgb(16, 51, 80);
$mhx-logo-color: rgb(69, 121, 158);
$btn-padding: 10px 20px;
$btn-border-radius: 5%;
$btn-margin: 20px;

// status colors: colorblind colors from http://colorbrewer2.org/#type=qualitative&scheme=Set2&n=3 (slightly edited)

// new
$newPastel: #66c2a5;
$new: #1b9e77;
$newHover: #096b4e;

// edited
$editedPastel: #c2d4ff;
$edited: #8da0cb;
$editedHover: #7570b3;

// clear
$cleanPastel: #fff;
$clean: #fff;
$cleanHover: #fff;

// deleted
$deletedPastel: #fc8d62;
$deleted: #d95f02;
$deletedHover: #b64f00;

// error
$errorPastel: #d4494e;
$error: #cb181d;
$errorHover: #880509;
